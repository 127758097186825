$city-white: #ebebeb;
$city-grey: #8c8c8c;
$city-black: #111111;
$city-orange: #fa7416;
$city-purple: #a9328a;
$city-green: #36c269;
$city-blue: #0c71fa;
$city-gold: #fcc32c;
$city-politics-red: #d02d3c;
$city-politics-blue: #214da5;
