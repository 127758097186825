@font-face {
  font-family: "Sharp Grotesk";
  font-display: auto;
  src: url("/assets/fonts/SharpGroteskSmBold15.woff") format("woff"),
    url("/assets/fonts/SharpGroteskSmBold15.woff2") format("woff2");
  font-weight: 900;
}

@font-face {
  font-family: "Sharp Grotesk";
  font-display: auto;
  src: url("/assets/fonts/SharpGroteskSmBoldItalic15.woff") format("woff"),
    url("/assets/fonts/SharpGroteskSmBoldItalic15.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Sharp Grotesk";
  font-display: auto;
  src: url("/assets/fonts/SharpGroteskMedium20.woff") format("woff"),
    url("/assets/fonts/SharpGroteskMedium20.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Sharp Grotesk";
  font-display: auto;
  src: url("/assets/fonts/SharpGroteskMediumItalic20.woff") format("woff"),
    url("/assets/fonts/SharpGroteskMediumItalic20.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Sharp Grotesk";
  font-display: auto;
  src: url("/assets/fonts/SharpGroteskBook20.woff") format("woff"),
    url("/assets/fonts/SharpGroteskBook20.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Sharp Grotesk";
  font-display: auto;
  src: url("/assets/fonts/SharpGroteskBookItalic20.woff") format("woff"),
    url("/assets/fonts/SharpGroteskBookItalic20.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Sharp Grotesk";
  font-display: auto;
  src: url("/assets/fonts/SharpGroteskLight20.woff") format("woff"),
    url("/assets/fonts/SharpGroteskLight20.woff2") format("woff2");
  font-weight: 200;
}

@font-face {
  font-family: "Sharp Grotesk";
  font-display: auto;
  src: url("/assets/fonts/SharpGroteskLightItalic20.woff") format("woff"),
    url("/assets/fonts/SharpGroteskLightItalic20.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
}

$sans: "Sharp Grotesk", Helvetica, sans-serif;
$serif: "Georgia", serif;
$mono: "Courier", monospace;

@mixin boldface() {
  font-family: $sans;
  font-weight: 900;
}

@mixin boldface-ital() {
  font-family: $sans;
  font-weight: 900;
  font-style: italic;
}

@mixin medface() {
  font-family: $sans;
  font-weight: 700;
}

@mixin medface-ital() {
  font-family: $sans;
  font-weight: 700;
  font-style: italic;
}

@mixin bookface() {
  font-family: $sans;
  font-weight: 400;
}

@mixin ital() {
  font-family: $sans;
  font-weight: 400;
  font-style: italic;
}

@mixin lightface() {
  font-family: $sans;
  font-weight: 200;
}

@mixin lightface-ital() {
  font-family: $sans;
  font-weight: 200;
  font-style: italic;
}
